<template>
  <mobile-screen :header="true" screen-class="profile-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header">
        <template v-slot:left>
          <router-link :to="{ name: 'r_translations-select-section' }">
            <svg-icon icon="arrow-left"></svg-icon>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("translations", "pages", "title") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('translations', 'pages', 'search')"
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>
    <ul class="named-list" v-if="listOfPages && listOfPages.length">
      <li class="name-wrapper">
        <p class="tiny-label bg">
          {{ displayLabelName("translations", "pages", "label") }}
        </p>
        <ul class="bordered-list">
          <li class="list-item" v-for="page in listOfPages" :key="page.id">
            <div class="checkbox tiny block">
              <input
                type="radio"
                :id="`page${page.id}`"
                name="page"
                :checked="page.id === selectedPage"
                :value="page.id"
                @change="selectPage(page.id)"
              />
              <label :for="`page${page.id}`">
                <svg-icon icon="checkmark-strait"></svg-icon>
                {{ page.name }}</label
              >
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedPage || processing"
              @click="selectSinglePage"
            >
              {{ displayLabelName("translations", "pages", "select") }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedPage || processing"
              @click="goToLabels"
            >
              {{ displayLabelName("translations", "pages", "open") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import translationsMixin from "@/services/mixins/translations/translations-mixin";

export default {
  name: "Pages",
  data() {
    return {
      selectedPage: 0,
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("translation", ["pages", "selectedSection"]),
    listOfPages() {
      const query = this.searchQuery;
      if (this.pages && this.pages.data && this.pages.data.length) {
        if (query && query.length > 1) {
          return this.pages.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.pages.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("translation", ["setSelectedPage", "getLabels"]),
    selectPage(id) {
      this.selectedPage = id;
      this.getLabels({ pageId: id, lang: this.selectedLanguage.locale });
    },
    selectSinglePage() {
      this.setSelectedPage(
        this.pages.data.filter(item => item.id === this.selectedPage)
      );
      this.$router.push({ name: "r_selected-page" });
    },
    goToLabels() {
      this.$router.push({ name: "r_translations-select-label" });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [translationsMixin]
};
</script>
